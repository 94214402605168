var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-container',{attrs:{"fluid":""}},[_c('v-row',[_c('v-col',{attrs:{"cols":"12","sm":"6"}},[_c('v-breadcrumbs',{attrs:{"large":"","items":_vm.breadcrumbsItems}})],1)],1),_c('v-row',{staticClass:"display-flex align-center"},[_c('v-col',{attrs:{"cols":"6","md":"2","sm":"2"}},[_c('v-img',{attrs:{"width":"130px","src":require("@/assets/img/logo_512_negro.png")}})],1),_c('v-col',{attrs:{"cols":"12","md":"7","sm":"5"}},[_c('v-row',[_c('v-col',{attrs:{"cols":"12","md":"8","sm":"8"}},[_c('h4',{staticClass:"text-h4"},[_vm._v(_vm._s(_vm.procesoData?.nombre_proceso))])])],1)],1),_c('v-col',{attrs:{"cols":"12","md":"3","sm":"3"}},[(
        _vm.procesoData?.id_seguimiento_proceso === 11 &&
        _vm.haveRoles(['ROLE_UACI', 'ROLE_UACI_TECNICO'])
      )?_c('v-btn',{attrs:{"block":"","color":"secondary","to":{
        name: 'pac-procesos-configuracion-v1',
        params: { idProceso: _vm.$route.params.idProceso },
      }}},[_vm._v(" Configuración del proceso "),_c('v-icon',{attrs:{"color":"white"}},[_vm._v("mdi-link-variant")])],1):_vm._e(),(
        _vm.procesoData?.id_seguimiento_proceso === 3 &&
        _vm.haveRoles(['ROLE_PROVEEDOR']) &&
        !this.participando
      )?_c('v-btn',{attrs:{"block":"","color":"secondary"},on:{"click":function($event){_vm.modalConfirmarParticipacion = true}}},[_vm._v(" Participar ")]):_vm._e()],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('app-tabs',{attrs:{"contenido":_vm.contenido,"items":_vm.tabs,"id":Number(_vm.id)}})],1)],1),_c('ConfirmationDialogComponent',{attrs:{"show":_vm.modalConfirmarParticipacion,"btnConfirmar":"Continuar","title":`¿Desea inscribirse en el proceso?`,"message":"Esta acción es irreversible después de realizada"},on:{"close":function($event){_vm.modalConfirmarParticipacion = false},"confirm":function($event){return _vm.inscripcionProceso()}}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }