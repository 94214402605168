<template>
  <section>
    <div v-if="permisos.escribir">
      <v-card>
        <VueEditor v-model="contentEditor"/>
      </v-card>
      <v-row class="display-flex justify-end mt-4">
        <v-col cols="12" sm="3">
          <v-btn
            block
            color="secondary"
            @click="guardarContenido"
          >
            Guardar información
          </v-btn>
        </v-col>
      </v-row>
    </div>
    <div v-else-if="permisos.leer" v-html="contentEditor"></div>
  </section>
</template>

<script>
import { VueEditor } from "vue2-editor";

export default {
  name: 'RichTextComponent',
  components: {
    VueEditor,
  },
  props: {
    etapa: {
      type: Object,
      default: {},
    },
    permisos: {
      type: Object,
      default: {},
    },
  },
  data: () => ({
    contentEditor: null,
  }),
  computed: {
    textContent() {
      if (this.etapa && this.permisos.leer) {
        return this.etapa.nombre
      }
    }
  },
  methods: {
    async guardarContenido() {

      const response = await this.services.PacProcesos.guardarContenidoEtapa(
        this.etapa.id,
        { contenido: this.contentEditor }
      ).catch(() => {

      });

      if (response.status === 200) {
        this.temporalAlert({
          show: true,
          type: 'success',
          message: 'Se ha actualizado la información con éxito'
        })
      }


    }
  },
  created() {
    this.contentEditor = this.etapa.observacion;
  }
}
</script>