<template>
  <section>
    <v-expansion-panels focusable>
      <v-expansion-panel
        v-for="(value, field) in list_docs_var"
        :key="value.id"
      >
        <v-expansion-panel-header> {{ field }} </v-expansion-panel-header>
        <v-expansion-panel-content>
          <v-data-table
            class="mt-7 mb-5"
            :headers="HEADERS_CONST"
            :items="value"
            item-key="id"
            :items-per-page="5"
          >
            <!-- Slot de acciones -->
            <template v-slot:[`item.acciones`] ="{ item }">
              <v-btn icon @click="DownloadFileFtn(item)">
                <v-icon>mdi-download</v-icon>
              </v-btn>
            </template>
          </v-data-table>
        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels>
  </section>
</template>

<script>
export default {
  data: () => ({
    // constantes
    HEADERS_CONST: [
      {
        text: "Documento",
        value: "nombre_documento",
      },
      {
        text: "Acciones",
        value: "acciones",
        sortable: false,
        align: "end",
      },
    ],

    // variables
    list_docs_var: [],
  }),
  computed: {},
  methods: {

    async DownloadFileFtn(item) {

      const response =
        await this.services.PacProcesos.descargarDocumentoProceso({
          ruta: item.ruta_documento,
        })
      if (!response) {
        this.temporalAlert({
          show: true,
          message: "Ocurrió un error al descargar el documento",
          type: "error",
        });

        return;
      }

      const blob = new Blob([response.data], {
        type: response.headers["content-type"],
      });

      const file = new File([blob], item.nombre_documento, {
        type: response.headers["content-type"],
      });

      const link = document.createElement("a");
      link.setAttribute("download", item.nombre_documento);
      link.href = window.URL.createObjectURL(file);
      link.download = "";
      link.click();

    },

    async FetchListDocs() {

      const RESP_SC_CONST = await this.services.PacProcesos.getListDocuments(
        this.$route.params.idProceso
      );
      if (RESP_SC_CONST) {
        const { data } = RESP_SC_CONST;
        this.list_docs_var = data;
      }

    },
  },
  created() {
    this.FetchListDocs();
  },
};
</script>

<style>
</style>