<template>
  <section>
    <v-row class="mb-2" v-if="permisos.escribir && showForm === true">
      <v-col cols="12" sm="4">
        <v-text-field
          outlined
          v-model="nombre"
          @input="$v.nombre.$touch()"
          @blur="$v.nombre.$touch()"
          :error-messages="nombreArchivoError"
          label="Nombre documento *"
          maxlength="75"
        />
      </v-col>
      <v-col cols="12" sm="4">
        <v-file-input
          v-model="archivo"
          outlined
          prepend-icon=""
          placeholder="Seleccione un archivo"
          label="Archivo *"
          @input="$v.archivo.$touch()"
          @blur="$v.archivo.$touch()"
          :error-messages="archivoError"
          prepend-inner-icon="mdi-paperclip"
          accept="application/pdf"
        />
      </v-col>
      <v-col cols="12" sm="4">
        <v-btn
          block
          large
          class="mt-2"
          color="secondary"
          @click="guardarArchivo"
          ><v-icon color="white">mdi-content-save</v-icon> Guardar</v-btn
        >
      </v-col>
    </v-row>

    <v-data-table
      :items="items"
      :headers="headers"
      hide-default-footer
      v-if="permisos.leer"
    >
      <template v-slot:[`item.unidad`]="{ item }">
        {{ item.Usuario?.Empleado?.EmpleadoUnidads[0]?.Unidad?.nombre }}
        {{ item.Usuario?.Proveedor?.nombre_comercial }}
      </template>
      <template v-slot:[`item.fecha_carga`]="{ item }">
        {{ moment(item.fecha_carga).format("DD/MM/YYYY") }}
      </template>

      <template v-slot:[`item.acciones`]="{ item }">
        <v-tooltip right>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              dark
              icon
              v-on="on"
              v-bind="attrs"
              color="secondary"
              @click="descargarArchivo(item)"
            >
              <v-icon>mdi-download</v-icon>
            </v-btn>
          </template>
          <span>Descargar archivo</span>
        </v-tooltip>

        <v-tooltip
          right
          v-if="
            permisos.leer &&
            permisos.escribir &&
            item.id_usuario === userInfo.user.id
          "
        >
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              dark
              icon
              v-on="on"
              v-bind="attrs"
              color="secondary"
              @click="
                (itemSelected = item), (showModalDeleteProcessDocument = true)
              "
            >
              <v-icon>mdi-delete</v-icon>
            </v-btn>
          </template>
          <span>Eliminar archivo</span>
        </v-tooltip>
      </template>
    </v-data-table>

    <ConfirmationDialogComponent
      :show="showModalDeleteProcessDocument"
      :title="`¿Desea eliminar el documento seleccionado?`"
      message="Esta acción es irreversible"
      @close="(showModalDeleteProcessDocument = false), (itemSelected = null)"
      @confirm="eliminarArchivo()"
    />
  </section>
</template>

<script>
import ConfirmationDialogComponent from "@/components/ConfirmationDialogComponent.vue";
import { helpers, required } from "vuelidate/lib/validators";
import LegacyValidations from "@/utils/legacy-validations";
import { mapState } from "vuex";
import { userInfo } from "os";

const txtField = helpers.regex(
  "txtArea",
  /^[A-Za-z0-9-&.,:;()À-ÿ\u00f1\u00d1 ]*$/
);

function fileTypeValid(value) {
  if (!value) {
    return true;
  }
  let extension = /(\.pdf)$/i;
  let file = value;
  return extension.test(file.name);
}

function fileNameValid(value) {
  if (!value) {
    return true;
  }
  let regAlfaNumPdf = /^[a-zA-Z0-9ñÑáéíóúÁÉÍÓÚ\-_\s]+(?=\.pdf$)/;
  let file = value;
  return regAlfaNumPdf.test(file.name);
}

export default {
  name: "AdminDocumentosComponent",
  components: {
    ConfirmationDialogComponent,
  },
  props: {
    etapa: {
      type: Object,
      default: {},
    },
    permisos: {
      type: Object,
      default: {},
    },
    extraParams: {
      type: Object,
    },
    showForm: {
      type: Boolean,
      default: true,
    },
  },
  data: () => ({
    headers: [
      {
        text: "Documento",
        value: "nombre_documento",
      },
      {
        text: "Fecha de carga",
        value: "fecha_carga",
      },
      {
        text: "Subido por",
        value: "unidad",
      },
      {
        text: "Acciones",
        value: "acciones",
        align: "center",
      },
    ],
    items: [],
    archivo: null,
    nombre: null,
    itemSelected: null,
    showModalDeleteProcessDocument: false,
  }),
  validations: {
    nombre: {
      required,
      txtField,
    },
    archivo: {
      required,
      fileTypeValid,
      fileNameValid,
      fileSizeValid: LegacyValidations.maxFileSizeRule(),
    },
  },
  computed: {
    ...mapState(["userInfo"]),
    nombreArchivoError() {
      const errors = [];
      if (!this.$v.nombre.$dirty) return errors;
      !this.$v.nombre.required &&
        errors.push("El nombre del archivo es requerido");
      !this.$v.nombre.txtField &&
        errors.push(
          "El nombre del archivo no es válido, solo se permiten letras, números y guiones"
        );
      return errors;
    },
    archivoError() {
      const errors = [];
      if (!this.$v.archivo.$dirty) return errors;
      !this.$v.archivo.required && errors.push("El archivo es requerido");
      !this.$v.archivo.fileTypeValid &&
        errors.push("El archivo debe ser de tipo PDF");
      !this.$v.archivo.fileNameValid &&
        errors.push(
          "El nombre del archivo no es válido"
        );
      !this.$v.archivo.fileSizeValid && LegacyValidations.pushToErrorsArray(errors);          
      return errors;
    },
  },
  methods: {
    async guardarArchivo() {
      this.$v.$touch();
      if (!this.$v.$invalid) {

        let formData = new FormData();
        formData.append("documento", this.archivo);
        formData.append("nombre", this.nombre);
        formData.append("id_proceso", this.$route.params.idProceso);
        formData.append("id_tipo_documento", this.etapa.id_tipo_documento);
        formData.append("folder", "documentos_hito");

        const response =
          await this.services.PacProcesos.guardarDocumentosProceso(
            formData
          ).catch(() => {

          });

        if (response.status === 200) {
          this.temporalAlert({
            show: true,
            type: "success",
            message: "Documento cargado correctamente",
          });
          this.getDocumentosEtapa();
          this.archivo = null;
          this.nombre = null;
          this.$v.$reset();
        }


      }
    },
    async getDocumentosEtapa() {
      if (!this.permisos.leer) return;



      let response = null;
      if (!this.haveRole("ROLE_PROVEEDOR")) {
        response = await this.services.PacProcesos.obtenerDocumentosProceso(
          this.$route.params.idProceso,
          this.etapa.id_tipo_documento,
          {
            ...this.addExtraParams(),
          }
        )
      } else {
        response =
          await this.services.PacProcesos.obtenerDocumentosProcesoProvider(
            this.$route.params.idProceso,
            this.etapa.id_tipo_documento,
            {
              ...this.addExtraParams(),
            }
          ).catch(() => {

          });
      }

      if (response.status === 200) {
        this.items = response.data;
      }


    },
    addExtraParams() {
      return this.extraParams ?? {};
    },
    async descargarArchivo(item) {

      const response =
        await this.services.PacProcesos.descargarDocumentoProceso({
          ruta: item.ruta_documento,
        })
      if (!response) {
        this.temporalAlert({
          show: true,
          message: "Ocurrió un error al descargar el documento",
          type: "error",
        });

        return;
      }

      const blob = new Blob([response.data], {
        type: response.headers["content-type"],
      });

      const file = new File([blob], item.nombre_documento, {
        type: response.headers["content-type"],
      });

      const link = document.createElement("a");
      link.href = window.URL.createObjectURL(file);
      link.download = "";
      link.click();

    },
    async eliminarArchivo() {

      const response = await this.services.PacProcesos.deleteProcessDocument(
        this.itemSelected.id
      )

      if (response?.status === 200) {
        this.showModalDeleteProcessDocument = false;
        this.itemSelected = null;
        this.getDocumentosEtapa();

        this.temporalAlert({
          show: true,
          type: "success",
          message: "Se ha eliminado el documento seleccionado",
        });
      }


    },
  },
  created() {
    this.getDocumentosEtapa();
  },
};
</script>
