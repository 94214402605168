<template>
  <v-container fluid>
    <v-row>
      <v-col cols="12" sm="6">
        <v-breadcrumbs large :items="breadcrumbsItems"></v-breadcrumbs>
      </v-col>
    </v-row>
    <v-row class="display-flex align-center">
      <v-col cols="6" md="2" sm="2">
        <v-img width="130px" src="@/assets/img/logo_512_negro.png"></v-img>
      </v-col>
      <v-col cols="12" md="7" sm="5">
        <v-row>
          <v-col cols="12" md="8" sm="8">
            <h4 class="text-h4">{{ procesoData?.nombre_proceso }}</h4>
          </v-col>
        </v-row>
      </v-col>
      <v-col cols="12" md="3" sm="3">
        <v-btn block color="secondary" :to="{
          name: 'pac-procesos-configuracion-v1',
          params: { idProceso: $route.params.idProceso },
        }" v-if="
          procesoData?.id_seguimiento_proceso === 11 &&
          haveRoles(['ROLE_UACI', 'ROLE_UACI_TECNICO'])
        ">
          Configuración del proceso
          <v-icon color="white">mdi-link-variant</v-icon>
        </v-btn>
        <v-btn block color="secondary" @click="modalConfirmarParticipacion = true" v-if="
          procesoData?.id_seguimiento_proceso === 3 &&
          haveRoles(['ROLE_PROVEEDOR']) &&
          !this.participando
        ">
          Participar
        </v-btn>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12">
        <app-tabs :contenido="contenido" :items="tabs" :id="Number(id)" />
      </v-col>
    </v-row>

    <ConfirmationDialogComponent :show="modalConfirmarParticipacion" btnConfirmar="Continuar"
      :title="`¿Desea inscribirse en el proceso?`" message="Esta acción es irreversible después de realizada"
      @close="modalConfirmarParticipacion = false" @confirm="inscripcionProceso()" />
  </v-container>
</template>
<script>
import EtapaActualComponent from "./components/EtapaActualComponent.vue";
import InfoGeneralComponent from "./components/InfoGeneralComponent.vue";
import PIPComponent from "./components/PIPComponent.vue";
import ParticipantesComponent from "./components/ParticipantesComponent.vue";
import ConfirmationDialogComponent from "@/components/ConfirmationDialogComponent.vue";
import DocumentsProccessComponent from "./components/DocumentsProccessComponent.vue";
import DocProveedorComponent from "./components/DocProveedorComponent.vue";
import { mapState, mapMutations } from "vuex";
export default {
  name: "PIPEjecucionView",
  components: {
    EtapaActualComponent,
    InfoGeneralComponent,
    PIPComponent,
    ParticipantesComponent,
    ConfirmationDialogComponent,
    DocumentsProccessComponent,
    DocProveedorComponent,
  },
  data: () => ({
    proceso: {},
    id: null,
    modalPublicarProceso: false,
    modalConfirmarParticipacion: false,
  }),
  computed: {
    ...mapState("procesoCompraDoc", ["procesoData", "participando"]),
    contenido() {
      return [
        {
          tab: "Etapa Actual",
          content: EtapaActualComponent,
          hide: [5, 6].includes(this.procesoData.id_estado_proceso),
        },
        {
          tab: "General",
          content: InfoGeneralComponent,
        },
        {
          tab: "Plan de implementación del proceso",
          content: PIPComponent,
        },
        {
          tab: "Documentos del proceso",
          content: DocumentsProccessComponent,
          hide: !this.haveRoles([
            "ROLE_UACI",
            "ROLE_UACI_TECNICO",
            "ROLE_DIRECCION_COMPRAS",
            "ROLE_TECNICO_DIRECCION_COMPRAS",
            "ROLE_OFICIAL_CUMPLIMIENTO",
          ]),
        },
        {
          tab: "Participantes",
          content: ParticipantesComponent,
          hide: this.haveRoles(["ROLE_PROVEEDOR"]),
        },
        {
          tab: "Documentos para el proveedor",
          content: DocProveedorComponent,
          hide: !this.haveRoles([
            "ROLE_PROVEEDOR",
            "ROLE_UACI",
            "ROLE_UACI_TECNICO",
            "ROLE_DIRECCION_COMPRAS",
            "ROLE_TECNICO_DIRECCION_COMPRAS",
            "ROLE_OFICIAL_CUMPLIMIENTO",
          ]),
          props: {
            proceso: this.procesoData,
          },
        },
      ];
    },
    tabs() {
      return [
        {
          text: "Etapa Actual",
          icono: "mdi-flag-triangle",
          hide: [5, 6].includes(this.procesoData.id_estado_proceso),

        },
        {
          text: "General",
          icono: "mdi-clipboard-list",
        },
        {
          text: "Plan de implementación del proceso",
          icono: "mdi-format-list-numbered",
        },
        {
          text: "Documentos del proceso",
          icono: "mdi-file-document-multiple",
          hide: !this.haveRoles([
            "ROLE_UACI",
            "ROLE_UACI_TECNICO",
            "ROLE_DIRECCION_COMPRAS",
            "ROLE_TECNICO_DIRECCION_COMPRAS",
            "ROLE_OFICIAL_CUMPLIMIENTO",
          ]),
        },
        {
          text: "Participantes",
          icono: "mdi-account-multiple-outline",
          hide: this.haveRoles(["ROLE_PROVEEDOR"]),
        },
        {
          text: "Documentos para el proveedor",
          icono: "mdi-text-box-multiple-outline",
          hide: !this.haveRoles([
            "ROLE_PROVEEDOR",
            "ROLE_UACI",
            "ROLE_UACI_TECNICO",
            "ROLE_DIRECCION_COMPRAS",
            "ROLE_TECNICO_DIRECCION_COMPRAS",
            "ROLE_OFICIAL_CUMPLIMIENTO",
          ]),
        },
      ];
    },
    breadcrumbsItems() {
      return [
        {
          text: "Proceso",
          disable: false,
          href: "/pac-procesos",
        },
        {
          text: `${this.procesoData?.codigo_proceso} / ${this.procesoData?.codigo_interno}`,
          disable: true,
        },
      ];
    },
  },
  methods: {
    ...mapMutations("procesoCompraDoc", ["setInfoProceso", "setParticipando"]),
    async getProceso() {
      const response = await this.services.PacProcesos.getProceso(
        this.$route.params.idProceso
      );

      if (response.status === 200) {
        this.setInfoProceso(response.data);
      }
    },
    async cambiarEstadoProceso() {

      const response =
        await this.services.PacProcesos.cambioEstadoProcesoCompra(
          this.$route.params.idProceso,
          1
        );

      if (response.status === 200) {
        this.temporalAlert({
          show: true,
          type: "success",
          message: "El proceso ha sido actualizado con éxito",
        });
        this.modalPublicarProceso = false;
        this.$router.replace({ name: "pac-procesos" });
      }


    },
    async validarParticipacion() {

      const response = await this.services.PacProcesos.validarParticipacion(
        this.$route.params.idProceso
      );

      if (response.status === 200)
        this.setParticipando(response.data.message || false);

    },
    async inscripcionProceso() {

      const response = await this.services.PacProcesos.inscripcionProceso(
        this.$route.params.idProceso
      );

      if (response.status === 200) {
        this.temporalAlert({
          show: true,
          type: "success",
          message: "Se ha inscrito en el proceso",
        });
        location.reload();
      }
    },
  },
  async created() {
    await this.getProceso();
    if (this.procesoData.version === 2) {
      this.$router.replace({
        name: "pac-procesos-etapas",
        params: { idProceso: this.procesoData.id },
      });
    }
    if (this.haveRole("ROLE_PROVEEDOR")) this.validarParticipacion();
  },
};
</script>
