<template>
  <section v-if="permisos.escribir">
    <v-row>
      <v-col cols="12" sm="3" v-if="etapa.id_tipo_etapa === 6 && haveRoles(['ROLE_UACI', 'ROLE_UACI_TECNICO'])">
        <v-btn
          block
          color="secondary"
          @click="addGanadorModal = true"
        >Añadir ganador</v-btn>
      </v-col>
      <v-col v-if="etapa.id_tipo_etapa === 2 && !fondosCertificados">
        <v-alert
          border="bottom"
          colored-border
          type="warning"
          icon="mdi-alert-outline"
          elevation="2"
        >
          No se ha realizado la certificación de fondos
        </v-alert>
      </v-col>
    </v-row>

    <v-data-table
      class="mt-4"
      :headers="headersGanadores"
      :items="ganadores"
    >
    <template v-slot:[`item.estado`]="{ item }">
        <v-chip
          style="display: block"
          label
          :color="Number(item?.contratos?.length) > 0 ? 'success' : 'info'"
          class="bgWhite--text"
        >
          {{ Number(item?.contratos?.length) > 0 ? "Contratado" : "Pendiente" }}
        </v-chip>
      </template>
      <template v-slot:[`item.monto`]="{ item }">
        $
        {{
          Intl.NumberFormat("en-US", {
            currency: "USD",
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
          }).format(item?.monto || 0)
        }}
      </template>
      <template v-slot:[`item.acciones`]="{ item }">
        <div v-if="etapa.id_tipo_etapa === 2">
          <v-btn
            v-if="!(Number(item.contratos?.length) > 0)"
            color="secondary"
            @click="generarContrato(item)"
            >Generar contrato</v-btn
          >
          <v-btn
            v-if="Number(item.contratos?.length) > 0 && haveRoles(['ROLE_UACI', 'ROLE_UACI_TECNICO'])"
            color="secondary"
            @click="generarContrato(item)"
            >Seguimiento</v-btn
          >
        </div>
      </template>
    </v-data-table>

    <v-dialog
      persistent
      v-model="addGanadorModal"
      max-width="600"
    >
      <v-card class="px-4 py-4">
        <v-card-text>
          <h5 class="text-h5 secondary--text mb-4">Agregar ganador</h5>
          <v-row>
            <v-col cols="12">
              <v-autocomplete
                v-model="form.proveedor"
                :items="listProveedores"
                @keyup="isLoading = true"
                item-text="nombre"
                item-value="id"
                outlined
                :loading="isLoading"
                label="Proveedor *"
                :error-messages="proveedorErrors"
                @input="$v.form.proveedor.$touch()"
                @blur="$v.form.proveedor.$touch()"
                @update:search-input="getProveedores"
                @click:clear="form.proveedor = null"
                clearable
                hide-no-data
              />
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12">
              <money-text-field-component
                ref="monto"
                label="Monto a adjudicar"
                v-model="form.monto"
                outlined
                :error-messages="montoErrors"
                @input="$v.form.monto.$touch()"
              />
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions>
          <v-row class="display-flex justify-end">
            <v-col cols="12" sm="3">
              <v-btn
                block
                outlined
                color="secondary"
                @click="addGanadorModal = false"
              >Volver</v-btn>
            </v-col>
            <v-col cols="12" sm="6">
              <v-btn
                block
                color="secondary"
                @click="guardarGanador"
              >Guardar ganador</v-btn>
            </v-col>
          </v-row>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </section>
</template>

<script>
import { mapMutations } from 'vuex';
import { required } from "vuelidate/lib/validators";
import MoneyTextFieldComponent from "@/components/MoneyTextFieldComponent.vue";

export default {
  name: 'ContratacionComponent',
  components: {
    MoneyTextFieldComponent
  },
  props: {
    etapa: {
      type: Object,
      default: {},
    },
    permisos: {
      type: Object,
      default: {},
    },
  },
  data: () => ({
    headersGanadores: [
      {
        text: 'Proveedor',
        value: 'nombre',
        width: '40%',
      },
      {
        text: 'Monto',
        value: 'monto',
        width: '20%',
        align: 'right',
      },
      {
        text: 'Estado',
        value: 'estado',
        width: '20%',
        align: 'center',
      },
      {
        text: 'Acciones',
        value: 'acciones',
        width: '20%',
        align: 'center',
      },
    ],
    ganadores: [],
    addGanadorModal: false,
    proveedor: null,
    listProveedores: [],
    isLoading: false,
    form: {
      proveedor: null,
      monto: null,
    },
    timer: null,
    fondosCertificados: false,
  }),
  validations: {
    form: {
      proveedor: {
        required,
      },
      monto: {
        required,
      },
    },
  },
  computed: {
    montoErrors() {
      const errors = [];
      if (!this.$v.form.monto.$dirty) return errors;
      !this.$v.form.monto.required && errors.push("El campo monto a adjudicar es obligatorio");
      return errors;
    },
    proveedorErrors() {
      const errors = [];
      if (!this.$v.form.proveedor.$dirty) return errors;
      !this.$v.form.proveedor.required && errors.push("El campo proveedor es obligatorio");
      return errors;
    },
  },
  methods: {
    ...mapMutations("agregarInsumo", ["setCurrentProvider", "setProviderInfo"]),
    async getProveedores(e) {
      if (this.timer) {
        clearTimeout(this.timer);
      }
      if (this.form.proveedor > 0) {
        return;
      }

      this.timer = setTimeout(async () => {
        let params = {
          busqueda: e,
        };

        const { data } = await this.services.PacProcesos.getProveedoresParaProceso(params);
        const elements = [];
        data.forEach((proveedor) => {
          elements.push({
            id: proveedor.id,
            nombre: proveedor.nombre,
          });
        });
        this.listProveedores = elements;
        this.isLoading = false;
      }, 500);
    },
    async guardarGanador() {
      this.$v.$touch();

      if (!this.$v.$invalid) {

        const response = await this.services.PacProcesosV1.saveGanadorProceso(this.$route.params.idProceso, {
          id_proveedor: this.form.proveedor,
          monto: this.form.monto,
        })

        if (response.status === 200) {
          this.temporalAlert({
            show: true,
            type: 'success',
            message: 'Se ha guardado el ganador con éxito'
          });
          this.addGanadorModal = false;
          this.form.proveedor = null;
          this.form.monto = null;
          this.$refs.monto.updateValue(this.form.monto);
          await this.obtenerGanadores();
        }

      }
    },
    async obtenerGanadores() {
      if (!this.permisos.leer  || this.haveRole('ROLE_PROVEEDOR')) return;
      

      const response = await this.services.PacProcesosV1.getGanadoresProcesos(this.$route.params.idProceso).catch(() => {

      });

      if (response.status === 200) {
        this.ganadores = response.data[0]?.subprocesos_obs[0]?.ganador_obs?.map((element) => ({
          id_ganador: element.id,
          contratos: element.contrato,
          id: element.id_subp_ganador,
          nombre: element.proveedor_nombre,
          monto: element.monto,
          id_proveedor: element.id_referencia
        }));
      }


    },
    async generarContrato(item) {

      if (item.contratos.length === 0) {
        let response =
          await this.services.ContratoService.getGanadorProveedorInfo(
            item.id_ganador,
          );
        if (response?.status == 200) {
          response.data.id_ganador = item.id_ganador;
          this.setCurrentProvider(item.id_proveedor);
          this.setProviderInfo(response?.data);

          const { status, data } =
            await this.services.ContratoService.getContratoGanador(
              item.id_ganador
            );
          if (status === 200 && data) {
            if (item.id_estado === 1) {
              this.setContratoExistente(data);
            }
          }

          this.$router.push({
            name: "crear-contrato-compra",
            params: { tipo: 3 },
          });
        }
      } else {
        this.$router.push({
          name: "seguimiento-orden-compra",
          params: { idContrato: item.contratos[0].id },
          query: { idProveedor: item.id_proveedor }
        });
      }

    },
    async verificarAsignacionCdf() {
      if (!this.permisos.leer  || this.haveRole('ROLE_PROVEEDOR')) return;


      const response = await this.services.PacProcesos.obtenerDocumentosProceso(
        this.$route.params.idProceso,
        1000,
      );

      if (response?.status === 200) this.fondosCertificados = response.data.length > 0;
    }
  },
  async created() {
    await this.verificarAsignacionCdf();
    await this.obtenerGanadores();
  }
}
</script>