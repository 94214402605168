<template>
  <PipProcesoComponent hideActions :etapas="procesoData?.EtapaPorProcesos" />
</template>
<script>
import PipProcesoComponent from "./PipProcesoComponent.vue";
import { mapState } from "vuex";
export default {
  name: "PIPComponent",
  components: {
    PipProcesoComponent,
  },
  computed: {
    ...mapState("procesoCompraDoc", ["procesoData"]),
  },
};
</script>
