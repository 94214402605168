<template>
  <v-container fluid class="mb-16">
    <!-- Vista inicial para proveedores antes de registrarse como participante -->
    <div v-if="!participando && haveRole('ROLE_PROVEEDOR')">
      <h1 class="secondary--text text-center mt-8">
        Debe inscribirse en el proceso para poder observar la etapa actual
      </h1>
    </div>

    <!-- Vista para todo tipo de usuario excepto proveedor cuando no hay etapa activa -->
    <div
      v-if="
        !participando &&
        !haveRole('ROLE_PROVEEDOR') &&
        etapas.length === 0 &&
        procesoData.id_seguimiento_proceso !== 11
      "
    >
      <h1 class="secondary--text text-center mt-8">
        No se ha encontrado etapa activa
      </h1>
    </div>

    <!-- Recepción de solicitudes de compra -->
    <template v-if="procesoData.id_seguimiento_proceso == 11">
      <div>
        <v-row class="display-flex align-center">
          <v-col cols="12" sm="6">
            <v-row align="center" class="pl-3">
              <h5 class="text-h5 secondary--text">{{ etapa?.nombre }}</h5>
              <v-menu
                offset-y
                v-if="
                  haveRoles([
                    'ROLE_UACI',
                    'ROLE_UACI_TECNICO',
                    'ROLE_DIRECCION_COMPRAS',
                    'ROLE_TECNICO_DIRECCION_COMPRAS',
                  ]) && procesoData.id_seguimiento_proceso !== 11
                "
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-btn icon class="mx-4" v-bind="attrs" v-on="on">
                    <v-icon color="secondary">mdi-cog</v-icon>
                  </v-btn>
                </template>
                <v-list>
                  <v-list-item>
                    <v-btn
                      class="secondary--text text-no-style"
                      text
                      @click="cerrarEtapa(etapa.id)"
                    >
                      Cerrar etapa
                    </v-btn>
                  </v-list-item>
                </v-list>
              </v-menu>
            </v-row>
          </v-col>
        </v-row>

        <v-row v-if="etapa?.FormaContratacionEtapa?.indicaciones?.length > 0">
          <v-col>
            <b>Indicaciones de hito:</b>
            {{ etapa?.FormaContratacionEtapa?.indicaciones }}
          </v-col>
        </v-row>

        <AdminDocumentosComponent
          v-if="etapa"
          :etapa="etapa"
          :permisos="permisosEtapaUsuario(etapa)"
        />
      </div>
    </template>

    <!--Seguimiento de etapas según estado -->
    <template
      v-if="
        (procesoData.id_seguimiento_proceso !== 11 &&
          haveRole('ROLE_PROVEEDOR') &&
          participando) ||
        (procesoData.id_seguimiento_proceso === 3 &&
          !haveRole('ROLE_PROVEEDOR'))
      "
    >
      <div v-for="etapa in etapas" :key="etapa.id">
        <v-row class="display-flex align-center">
          <v-col cols="12" sm="6">
            <v-row align="center">
              <h5 class="text-h5 secondary--text">{{ etapa?.nombre }}</h5>

              <v-menu
                v-if="
                  haveRoles([
                    'ROLE_UACI',
                    'ROLE_UACI_TECNICO',
                    'ROLE_DIRECCION_COMPRAS',
                    'ROLE_TECNICO_DIRECCION_COMPRAS',
                  ]) && procesoData.id_seguimiento_proceso !== 11
                "
                offset-y
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-btn icon class="mx-4" v-bind="attrs" v-on="on">
                    <v-icon color="secondary">mdi-cog</v-icon>
                  </v-btn>
                </template>
                <v-list>
                  <v-list-item>
                    <v-btn
                      class="secondary--text text-no-style"
                      text
                      @click="cerrarEtapa(etapa.id)"
                    >
                      Cerrar etapa
                    </v-btn>
                  </v-list-item>
                </v-list>
              </v-menu>
            </v-row>
          </v-col>
          <v-col cols="12" sm="3">
            <p class="h6-text secondary--text mb-0 mt-0">
              Fecha de Inicio: <br />
              {{
                moment(etapa?.fecha_hora_inicio).format("DD-MM-YYYY HH:mm A")
              }}
            </p>
          </v-col>
          <v-col cols="12" sm="3">
            <p class="h6-text secondary--text mb-0 mt-0">
              Fecha de Fin: <br />
              {{ moment(etapa?.fecha_hora_fin).format("DD-MM-YYYY HH:mm A") }}
            </p>
          </v-col>
        </v-row>

        <!-- Indicaciones generales de etapa -->
        <v-row v-if="etapa?.FormaContratacionEtapa?.indicaciones?.length > 0">
          <v-col>
            <b>Indicaciones de hito:</b>
            {{ etapa?.FormaContratacionEtapa?.indicaciones }}
          </v-col>
        </v-row>

        <!-- Etapas de seguimiento -->
        <v-row v-if="[1, 3, 4].includes(etapa?.id_tipo_etapa)">
          <v-col>
            <RichTextComponent
              :etapa="etapa"
              class="mb-8"
              :permisos="permisosEtapaUsuario(etapa)"
              v-if="[2].includes(etapa?.id_tipo_configuracion)"
            />
            <AdminDocumentosComponent
              :etapa="etapa"
              :permisos="permisosEtapaUsuario(etapa)"
              v-if="[1, 3, 4].includes(etapa?.id_tipo_configuracion)"
            />
          </v-col>
        </v-row>

        <!-- Contratación || adjudicación -->
        <v-row v-if="[2, 6].includes(etapa?.id_tipo_etapa)">
          <v-col>
            <ContratacionComponent
              :etapa="etapa"
              class="mb-8"
              :permisos="permisosEtapaUsuario(etapa)"
            />
          </v-col>
        </v-row>

        <!-- Apertura de ofertas -->
        <v-row v-if="[5].includes(etapa?.id_tipo_etapa)">
          <v-col>
            <AdminDocumentosComponent
              :etapa="etapa"
              :extra-params="{ add_documents: [4] }"
              :permisos="permisosEtapaUsuario(etapa)"
            />
          </v-col>
        </v-row>

        <!-- Asignación DE CDF -->
        <v-row
          v-if="
            [6].includes(etapa?.id_tipo_etapa) &&
            haveRoles([
              'ROLE_UFI',
              'ROLE_UFI_TECNICO',
              'ROLE_UACI',
              'ROLE_UACI_TECNICO',
            ])
          "
        >
          <v-col cols="12" v-if="haveRoles(['ROLE_UFI', 'ROLE_UFI_TECNICO'])">
            <AsignacionCdfComponent
              :etapa="etapa"
              :permisos="permisosEtapaUsuario(etapa)"
            />
          </v-col>
          <v-col cols="12" v-if="haveRoles(['ROLE_UFI', 'ROLE_UFI_TECNICO'])">
            <h5 class="text-h5 secondary--text mb-4">Solicitudes de compra</h5>
            <AdminDocumentosComponent
              :etapa="etapa"
              :show-form="false"
              :extra-params="{ add_documents: [3] }"
              :permisos="permisosEtapaUsuario(etapa)"
            />
          </v-col>
        </v-row>

        <v-divider v-if="etapas.length > 1" class="my-4"></v-divider>
      </div>
    </template>

    <template v-if="procesoData.id_seguimiento_proceso === 5 && haveRoles(['ROLE_UACI', 'ROLE_UACI_TECNICO'])">
      <div v-for="etapa in etapas" :key="etapa.id">
        <v-col cols="12" sm="6">
          <v-row align="center">
            <h5 class="text-h5 secondary--text">{{ etapa?.nombre }}</h5>
          </v-row>
        </v-col>
        <v-row v-if="[2].includes(etapa?.id_tipo_etapa)" class="mt-2">
          <v-col>
            <ContratacionComponent
              :etapa="etapa"
              class="mb-8"
              :permisos="permisosEtapaUsuario(etapa)"
            />
          </v-col>
        </v-row>
      </div>
    </template>
  </v-container>
</template>
<script>
  import { mapState } from "vuex";
  import AdminDocumentosComponent from "./Etapas/AdminDocumentosComponent.vue";
  import RichTextComponent from "./Etapas/RichTextComponent.vue";
  import ContratacionComponent from "./Etapas/ContratacionComponent.vue";
  import AsignacionCdfComponent from "./Etapas/AsignacionCdfComponent.vue";

  export default {
    name: "EtapaActualComponent",
    components: {
      AdminDocumentosComponent,
      RichTextComponent,
      ContratacionComponent,
      AsignacionCdfComponent,
    },
    data: () => ({
      etapas: [],
      etapa: null,
    }),
    computed: {
      ...mapState(["selectedUnidad"]),
      ...mapState("procesoCompraDoc", ["procesoData", "participando"]),
    },
    methods: {
      async getEtapasActuales() {

        const { status, data } =
          await this.services.PacProcesos.getProcesoEtapasActivas(
            this.$route.params.idProceso
          ).catch(() => {

          });

        if (status == 200) {
          if (data.length > 0) {
            this.etapas = data;
          } else {
            if ([5, 6, 8].includes(this.procesoData.id_seguimiento_proceso)) {
              const { status, data } =
                await this.services.PacProcesos.getEtapaSolicitudes(
                  this.$route.params.idProceso,
                  2
                ).catch(() => {

                });

              if (status == 200) {
                this.etapas = data;

              }
            }
          }


        }
      },
      async getEtapaSolicitudes() {

        const { status, data } =
          await this.services.PacProcesos.getEtapaSolicitudes(
            this.$route.params.idProceso,
            3
          ).catch(() => {

          });

        if (status == 200) {
          this.etapa = data[0];

        }
      },
      permisosEtapaUsuario(etapa) {
        const permisosEtapa =
          etapa?.FormaContratacionEtapa?.ParticipantesEtapaProcesos;
        const perfiles = this.selectedUnidad?.cargo?.map((element) =>
          Number(element.id)
        ) || [2];
        const permisos = permisosEtapa?.filter((element) => {
          if (perfiles?.includes(element.id_perfil)) return element;
        });

        if (this.haveRole("ROLE_SUPERVISOR") && this.$route.params.supervisor) {
          return {
            escribir: false,
            leer: this.getPermisoLecturaSupervisor(etapa),
          };
        } else {
          return permisos[0] || {};
        }
      },
      getPermisoLecturaSupervisor(etapa) {
        if (![2, 4, 5, 6].includes(etapa.id_tipo_etapa)) {
          return true;
        } else {
          const procesoPerteneceAInstitucionUsuario = (this.procesoData.id_institucion === this.selectedUnidad.instituciones.id);
          const usuarioPerteneceAUCP = this.haveRoles(['ROLE_UACI', 'ROLE_UACI_TECNICO']);

          return (procesoPerteneceAInstitucionUsuario && usuarioPerteneceAUCP);
        }
      },
      async cerrarEtapa(id_etapa) {
        const { status } = await this.services.PacProcesos.closeEtapaProceso(
          this.$route.params.idProceso,
          id_etapa
        );

        if (status == 200) {
          this.temporalAlert({
            show: true,
            message: `Etapa cerrada con éxito`,
            type: "success",
          });
          this.getEtapasActuales();
        }
      },
    },
    watch: {
      procesoData() {
        if (this.procesoData.id_seguimiento_proceso == 11) {
          this.getEtapaSolicitudes();
        } else {
          this.getEtapasActuales();
        }
      },
    },
  };
</script>
