<template>
  <v-container fluid>
    <v-row>
      <v-col cols="12">
        <v-data-table :headers="headers" :items="items">
          <!-- Template para valoraciones -->
          <template v-slot:[`item.ponderacion`]="{ item }">
            <v-rating
              half-increments
              :value="Number(item.ponderacion)"
              readonly
              color="secondary"
              dense
            ></v-rating>
          </template>

          <template v-slot:[`item.acciones`]="{ item }">
            <v-btn icon>
              <v-icon color="secondary" @click="verParticipante(item)"
                >mdi-eye</v-icon
              >
            </v-btn>
          </template>
        </v-data-table>
      </v-col>
    </v-row>
    <!-- Modal para ver la información de los participantes -->
    <app-dialog
      :max-width="1200"
      v-model="showModalVer"
      title="Oferta presentada"
    >
      <template #content>
        <InformacionProvedor :providerData="proveedor" />
      </template>
      <template #actions>
        <div class="mt-3">
          <v-btn
            color="secondary"
            @click="showModalVer = false"
            class="mr-4"
            outlined
          >
            Cerrar
          </v-btn>
        </div>
      </template>
    </app-dialog>
  </v-container>
</template>
<script>
import DataTable from "@/components/DataTableComponent.vue";
import InformacionProvedor from "@/components/InfomacionProveedorComponent.vue";
import { mapState } from "vuex";

export default {
  name: "ParticipantesComponent",
  components: {
    DataTable,
    InformacionProvedor,
  },
  data: () => ({
    showModalVer: false,
    proveedor: {},
    headers: [
      {
        text: "Participante",
        align: "start",
        sortable: true,
        value: "nombre_comercial",
      },
      {
        text: "Acciones",
        align: "center",
        sortable: true,
        value: "acciones",
      },
    ],
    items: [],
  }),
  computed:{
    ...mapState("procesoCompraDoc", ["procesoData"]),
  },
  methods: {
    async verParticipante(item) {
      const response = await this.services.Paac.getMostrarProveedor(item.id);

      if (response.status == 200) {
        this.proveedor = response.data;
        this.showModalVer = true;
      } else {
        this.temporalAlert({
          show: true,
          message: response.data.message,
          type: "error",
        });
      }
    },
    async obtenerParticipantes() {
      let response = null;

      if (
        this.procesoData.id_seguimiento_proceso == 1 &&
        (this.procesoData.id_forma_contratacion == 2 ||
          this.procesoData.id_forma_contratacion == 4)
      ) {
        response = await this.services.Paac.getProveedoresInvitados(
          this.procesoData.id
        );
      } else {
        response = await this.services.Paac.getProveedoresParticipantes(
          this.procesoData.id
        );
      }

      if (response != null && response.status == 200) {
        this.items = response.data;
      }
    },
  },
  created() {
    this.obtenerParticipantes();
  },
};
</script>
